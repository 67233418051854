/**
 * Created by cuigl on 08/04/2019.
 */

import key from './key'
import { Resource, Fetch } from '@ekuaibao/fetch'
import { showModal, showMessage } from '@ekuaibao/show-util'
import { isFunction } from '@ekuaibao/helpers'
import { app as api } from '@ekuaibao/whispered'
const users = new Resource('/api/app/account/users')
const account = new Resource('/api/account/v2/session')
const organization = new Resource('/api/v1/organization')
const appOrganization = new Resource('/api/app/organization')
const basedata = new Resource('/api/v1/basedata')
const register = new Resource('/api/v1/account/users')
const ldap = new Resource('/api/ldap')
const security = new Resource('/api/app/security')
const agreement = new Resource('/api/v2/staff/agreement')
const csxSsoAccount = new Resource('/api/account/v2/session/logoutsanxiang')
const updateCookie = new Resource('/api/authorization/v1/login')

// 是否授权
export function getMeAuthState() {
  return organization.GET('/staffs/me')
}


export function updatDomainCookie(params) {
  return updateCookie.GET('/facade', params)
}

export function modifySafeSettingAccount(params) {
  return register.POST('/bindAccount', params)
}

/**
 * 
 * @param {*} 
 * corpId:即将登陆得企业ID  checkInApplication:应用内/未登录场景 preExecute:检验密码执行得回调 checkOthers:其他参数
 * @returns false:不能直接登录，需要修改密码 
 */
export async function passwordExpireModal({ corpId, checkInApplication = false, preExecute, cancelCallback, okCallback, checkOthers }) {
  const content = '您的密码已过期,请前往修改密码'
  const cancelText = checkInApplication ? i18n.get('取消') : null
  const handleOk = () => {
    okCallback?.()
    if (checkInApplication) {
      api.go('/user-info/safeSetting?safeSetType=password', true)
    } else {
      api.go('/forgotPassword5/title')
    }
  }

  const handleCancel = () => {
    cancelCallback?.()
    if (checkOthers?.onlyOneCorp && !cancelCallback) {
      api.go('/login', true)
    }
  }

  try {
    const { value } = await checkPasswordExpire(corpId)
    if (!value) {
      // 有一些状态需要调用密码校验===false 时候取消掉，例如Loading
      if (preExecute && isFunction(preExecute)) { preExecute() }
      // 直接进入系统得情况，开启密码时效下需要进入到选择企业
      if (checkOthers && !checkOthers.onlyOneCorp && checkOthers.ekbCorpId) {
        cancelCallback?.()
        api.go('/selectEnterprise5')
        return
      }
      showModal.confirm({
        zIndex: 10000,
        title: i18n.get('密码过期提醒'),
        content: i18n.get(content),
        okText: i18n.get("确定"),
        cancelText: cancelText,
        onOk: handleOk,
        onCancel: handleCancel
      });
      return false
    }
    return true
  } catch (error) {
    showMessage(error)
    return true
  }
}

export function checkPasswordExpire(corpId) {
  return organization.POST('/staffs/checkPwdTime?targetCorpId=' + corpId)
}

export function basicLogin(data) {
  return {
    type: key.BASIC_ACCOUNT,
    payload: users.POST('/loginByV1', data)
  }
}

export function proLogin(data) {
  return {
    type: key.PRO_ACCOUNT,
    payload: account.POST('/loginByOne', data)
  }
}

export function smsLogin(data) {
  return {
    type: key.SMS_ACCOUNT,
    payload: account.POST('/loginBySMS', data)
  }
}
export function ldapLogin(data) {
  return {
    type: key.LDAP_ACCOUNT,
    payload: ldap.POST('/login', data)
  }
}

export async function getSecurityKey() {
  try {
    const result = await security.GET('/key')
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export function getCorporations(data) {
  return {
    type: key.CORPORATIONS,
    payload: organization.GET('/corporations', data)
  }
}

export function getCorporationsAndCount(data) {
  return {
    type: key.CORPORATIONS_COUNT,
    payload: organization.GET('/corporations/andCount', data)
  }
}

export function getCountryList(data) {
  return {
    type: key.GET_COUNTRY,
    payload: basedata.GET('/country', data)
  }
}

export function sendCaptcha(data, done, errorHandler) {
  return {
    type: key.SEND_CAPTCHA,
    payload: account.POST('/send', data, errorHandler),
    done
  }
}

export function sendVoiceCaptcha(data, done, errorHandler) {
  return {
    type: key.SEND_VOICE_CAPTCHA,
    payload: account.POST('/send', data, errorHandler),
    done
  }
}

export function sendRegisterVoiceCaptcha(data, done, errorHandler) {
  return {
    type: key.SEND_REG_VOICE_CAPTCHA,
    payload: register.POST('/machine/captcha', data, errorHandler),
    done
  }
}

export function getCaptcha(data) {
  return {
    type: key.GET_CAPTCHA,
    payload: register.POST('/machine/captcha', data)
  }
}

export function checkCaptcha(data) {
  return {
    type: key.CHECK_CAPTCHA,
    payload: register.GET('/checkCaptcha', data)
  }
}

export function accountRebind(data) {
  return {
    type: key.CHECK_CAPTCHA,
    payload: register.PUT('/rebind', data)
  }
}

export function accountNewbind(data) {
  return {
    type: key.CHECK_CAPTCHA,
    payload: register.PUT('/newbind', data)
  }
}

export function UserRegister(data, done) {
  return {
    type: key.USER_REGISTER,
    payload: register.POST('/register', data),
    done
  }
}

export function setPassword(data) {
  return {
    type: key.FORGET_UPDATE_PASSWORD,
    payload: register.PUT('/forget', data)
  }
}

export function createOrg(data) {
  return {
    type: key.CREATE_ORG,
    payload: users.POST('/createOrg', data)
  }
}

export function generateInviteLinks(data) {
  return {
    type: key.GENERATE_INVITE_LINKS,
    payload: appOrganization.POST('/staffs/invite', data)
  }
}

export function byCodeJoinEnterprise(data) {
  return {
    type: key.BY_CODE_JOIN_ENTERPRISE,
    payload: users.POST('/join', null, data)
  }
}

export function exitLogon(data) {
  return {
    type: key.EXIT_LOGON,
    payload: account.DELETE('', data)
  }
}

export function csxSsoLogout(data) {
  return {
    type: key.CSX_LOGOUT,
    payload: csxSsoAccount.DELETE('', data)
  }
}

export async function modifyPassword(data) {
  try {
    data.deviceType = 'DESKTOP'
    const result = await register.PUT('/update/pwd', data)
    if (result.errorCode) {
      return Promise.reject(result)
    }
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export function changeLocalLanguage(language) {
  return {
    type: key.CHANGE_LOCAL_LANGUAGE,
    language
  }
}

export async function getIsOnlyShowEmailPage() {
  try {
    const result = await users.GET('/onlyShowEmailPage')
    return result.value
  } catch (e) {
    return Promise.reject(e)
  }
}

export function getRead(data) {
  return {
    type: key.GET_READ,
    payload: agreement.POST('/read/agreement', data)
  }
}

export function checkConfirm(data) {
  return {
    type: key.CHECK_CONFIRM,
    payload: agreement.POST('/confirm/agreement', data)
  }
}

export function checkPhoneEmail(param) {
  const { type, code } = param
  return {
    type: key.CHECK_CAPTCHA,
    payload: register.GET(`/${type}/$code/exists`, { code })
  }
}

export function accountChangeBind(data) {
  return {
    type: key.CHECK_CAPTCHA,
    payload: register.PUT('/changeBind', data)
  }
}