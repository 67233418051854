/**
 * Created by wq on 28/12/2017.
 */
export const ID = '@account5'

export default {
  ID,
  CORPORATIONS: `${ID}/CORPORATIONS`,
  SEND_REG_VOICE_CAPTCHA: `${ID}/SEND_REG_VOICE_CAPTCHA`,
  SEND_VOICE_CAPTCHA: `${ID}/SEND_VOICE_CAPTCHA`,
  CORPORATIONS_COUNT: `${ID}/CORPORATIONS_COUNT`,
  GET_COUNTRY: `${ID}/GET_COUNTRY`,
  BASIC_ACCOUNT: `${ID}/BASIC_ACCOUNT`,
  PRO_ACCOUNT: `${ID}/PRO_ACCOUNT`,
  SMS_ACCOUNT: `${ID}/SMS_ACCOUNT`,
  LDAP_ACCOUNT: `${ID}/LDAP_ACCOUNT`,
  GET_CAPTCHA: `${ID}/GET_CAPTCHA`,
  SEND_CAPTCHA: `${ID}/SEND_CAPTCHA`,
  CHECK_CAPTCHA: `${ID}/CHECK_CAPTCHA`,
  USER_REGISTER: `${ID}/USER_REGISTER`,
  EXIT_LOGON: `${ID}/EXIT_LOGON`,
  CSX_LOGOUT: `${ID}/CSX_LOGOUT`,
  FORGET_UPDATE_PASSWORD: `${ID}/FORGET_UPDATE_PASSWORD`,
  CREATE_ORG: `${ID}/CREATE_ORG`,
  GENERATE_INVITE_LINKS: `${ID}/GENERATE_INVITE_LINKS`,
  CHANGE_LOCAL_LANGUAGE: `${ID}/CHANGE_LOCAL_LANGUAGE`,
  BY_CODE_JOIN_ENTERPRISE: `${ID}/BY_CODE_JOIN_ENTERPRISE`,
  GET_READ: `${ID}/GET_READ`,
  CHECK_CONFIRM: `${ID}/CHECK_CONFIRM`,
  SECURITY_KEY: `${ID}/SECURITY_KEY`
}
