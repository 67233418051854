import { __assign, __awaiter, __generator } from "tslib";
import { Fetch } from '@ekuaibao/fetch';
import { session } from '@ekuaibao/session-info';
import { updatDomainCookie } from '../account.action';
import { selectCorp2UpdateToken } from '@ekuaibao/auth';
// 费控环境对应统一认证环境区分
var devServerHost = 'https://bc.k8s01.ekuaibao.net'; // 测试
var preServerHost = 'https://bc-pre.ekuaibao.com'; // 预发布
var proServerHost = 'https://business-center.ekuaibao.com'; // 生产
// 测试
var devDomains = ['k8s01.ekuaibao.net', 'k8s03.ekuaibao.net', 'dev.ekuaibao.com.cn', 'localhost', 'qa-test.qhose.net'];
// 预发布
var preDomains = ['release.ekuaibao.net', 'hotfix.ekuaibao.net', 'ekb-pre.ekuaibao.com'];
// 生产
var proDomains = ['app.ekuaibao.com'];
export function AuthLoginSelectCorp2UpdateToken(param) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var environment, host, data, user, updCookieParam, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    environment = (_a = window === null || window === void 0 ? void 0 : window.PLATFORMINFO) === null || _a === void 0 ? void 0 : _a.platform;
                    host = getBusinessCenterHost();
                    return [4 /*yield*/, selectCorp2UpdateToken(__assign({ host: host, environment: environment }, param))
                        // 第一步：更换新得 费控token，第二步：废弃登录接口，使用统一认证接口下发
                        // 更新token->idToken,传递企业id后得更新token，后续供其他业务域使用，具有企业信息得token
                        // 1.通过updatDomainCookie接口验证token有效性，验证通过写入cookie
                        // 2.更新Fetch->token
                        // 3.更新session->token
                        // 4.验证失败则前往登陆页面
                    ];
                case 1:
                    data = _b.sent();
                    if (!((data === null || data === void 0 ? void 0 : data.code) === "200" || (data === null || data === void 0 ? void 0 : data.code) === 200)) return [3 /*break*/, 6];
                    user = session.get('user') || {};
                    updCookieParam = {
                        corpId: param.corpId,
                        facadeUrl: "X",
                        accessToken: data === null || data === void 0 ? void 0 : data.data
                    };
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, updatDomainCookie(updCookieParam)];
                case 3:
                    _b.sent();
                    console.log('------此处逻辑不会走------');
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _b.sent();
                    // updatDomainCookie接口无返回值，前端catch住
                    // 没有捕获到error异常时，为成功则更新cookie及token
                    // @ts-ignore
                    if (!(error_1 === null || error_1 === void 0 ? void 0 : error_1.errorCode)) {
                        // 1.updatDomainCookie接口验证成功
                        Fetch.accessToken = data === null || data === void 0 ? void 0 : data.data;
                        user = __assign(__assign({}, user), { accessToken: data === null || data === void 0 ? void 0 : data.data });
                        session.set('user', user);
                    }
                    // 2.updatDomainCookie接口验证失败
                    console.log('-----error-----', error_1);
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    if ((data === null || data === void 0 ? void 0 : data.code) === "002-001-P-003") {
                        // 后台未开放灰度
                        console.log('------不在灰度测试范围------', data.message);
                    }
                    _b.label = 7;
                case 7:
                    console.log('-----data-----', data);
                    return [2 /*return*/];
            }
        });
    });
}
function getBusinessCenterHost() {
    var localHost = location.host;
    // 测试
    if (devDomains.includes(localHost)) {
        return devServerHost;
    }
    // 预发布
    if (preDomains.includes(localHost)) {
        return preServerHost;
    }
    // 生产
    if (proDomains.includes(localHost)) {
        return proServerHost;
    }
    return devServerHost;
}
