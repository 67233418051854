import { isEmail } from '@ekuaibao/lib/lib/help';
export var validators = {
    required: function (message) {
        return { required: true, message: message };
    },
    checkPhone: function (areaCode) {
        return function (rule, value, fn) {
            if (!value || areaCode !== '86' || /^1\d{10}$/.test(value)) {
                fn();
            }
            else {
                fn(new Error(i18n.get('手机格式不正确')));
            }
        };
    },
    checkCaptchaCode: function () {
        return function (rule, value, fn) {
            if (!value || value.length === 6) {
                fn();
            }
            else {
                fn(new Error(i18n.get('验证码格式不正确')));
            }
        };
    },
    checkEmail: function () {
        return function (rule, value, fn) {
            if (!value || isEmail(value)) {
                fn();
            }
            else {
                fn(new Error(i18n.get('邮箱格式不正确')));
            }
        };
    },
    max: function (label, max) {
        return { max: max, message: label + i18n.get('不能超过') + max + i18n.get('位') };
    },
};
/**
 * detect client browser
 */
export function detectClientBrowser() {
    var _userAgent = navigator.userAgent;
    var _broMark = '';
    if (/DingTalk/gi.test(_userAgent)) {
        //判断是否是钉钉客户端
        _broMark = 'DingTalk';
        return _broMark;
    }
    else if (/wxWork/gi.test(_userAgent)) {
        //判断是否是微信客户端
        _broMark = 'wxWork';
        return _broMark;
    }
    //如果是手机浏览器
    if (/Mobile/gi.test(_userAgent)) {
        if (/MicroMessenger/gi.test(_userAgent)) {
            _broMark = 'wx';
        }
        else if (/ycBrowser/gi.test(_userAgent)) {
            _broMark = 'yx';
        }
        else {
            _broMark = 'browser';
        }
    }
    return _broMark;
}
export function initializeFormProps(form, data) {
    return function (field, validators) {
        return form.getFieldDecorator(field, {
            initialValue: data[field],
            rules: validators,
        });
    };
}
export function isOPG() {
    return location.origin.includes('opg.cn');
}
// 判断是否为集群MC环境
export function isClusterMC() {
    var _a, _b;
    return ((_a = window.PLATFORMINFO) === null || _a === void 0 ? void 0 : _a.clusterURL) && ((_b = window.PLATFORMINFO) === null || _b === void 0 ? void 0 : _b.loginEnv) === 'MC';
}
