/**
 * Created by cuigl on 08/04/2019.
 */
import { Fetch } from '@ekuaibao/fetch';
import { session } from '@ekuaibao/session-info';
import { AuthLoginSelectCorp2UpdateToken } from './utils/accountUtil'
import Cookies from 'js-cookie';
import { showMessage } from '@ekuaibao/show-util';
import { getV } from '@ekuaibao/lib/lib/help'
import { detectClientBrowser } from './utils/loginCardUtil';
import { go2AuthCheck } from './utils/utilSource';

import { app as api } from '@ekuaibao/whispered';
import qs from 'qs';
const actions = require('./account.action');

function handleRefresh(path) {
  api.dispatchO(() => ({ type: '@@redux/INIT' }));
  setTimeout(() => {
    //api.go(-1)
    location.replace(path);
  }, 4);
}

function handleLogin() {
  const urlstr = location.hash;

  if (urlstr.indexOf('#/register') !== -1) {
    api.go('/register5');
    return;
  }
  const user = session.get('user');
  if (user) {
    Fetch.ekbCorpId = user.corpId;
  } else {
    console.log('=====clear-session=====');
    session.clear();
  }
  let params = window.inGroupApp ? { accessToken: Fetch.accessToken } : undefined;
  const searchParams = qs.parse(location.search.slice(1));
  if (searchParams && searchParams?.billentry && !params) {
    console.log('=====start-account-log=====');
    console.log('ekbCorpId=====' + searchParams.ekbCorpId);
    console.log('accessToken=====' + searchParams.accessToken);
    console.log('provisionalToken=====' + searchParams.provisionalToken);
    console.log('===Fetch===accessToken=====' + Fetch.accessToken);
    console.log('=====end--log=====');
    if (searchParams.ekbCorpId) {
      // 如果通过路径上有billentry这个参数说明是从第三方授权进入的，重新给Fetch.ekbCorpId赋值
      Fetch.ekbCorpId = searchParams.ekbCorpId;
      if (searchParams?.accessToken?.length) {
        Fetch.accessToken = searchParams.accessToken;
      }
      if (!Fetch.accessToken?.length && searchParams.provisionalToken?.length) {
        Fetch.accessToken = searchParams.provisionalToken;
      }
    }
    params = { accessToken: Fetch.accessToken };
  }

  if (searchParams?.fromCluster && searchParams?.corpId) {
    Fetch.ekbCorpId = searchParams.corpId;
  }

  Fetch.GET('/api/account/v2/session', params)
    .then((result) => {
      Fetch.accessToken = result.value.id;
      Fetch.isAssistPlatform = result.value.isAssistPlatform;
      i18n
        .changeLanguage(Fetch.defaultLanguage)
        .then(() => {
          fnJumpURL();
        })
        .catch((e) => {
          fnJumpURL();
        });
    })
    .catch((err) => {
      session.clear();
      Fetch.go2RedirectUrl(err).then(() => {
        if (err.status === 401 || err.status === 403) {
          if (window.inGroupApp && window.APP_URL) {
            // 在集团版中时，跳转至原生APP的登录页
            location.href = `${window.APP_URL}?formGroupApp=true#/login`;
          } else {
            api.go('/login');
          }
        } else {
          alert(err.message || err.msg || err);
        }
      });
    });
}

function fnJumpURL() {
  Fetch.GET('/api/v1/organization/corporations/andCount').then((data) => {
    const list = data.items;
    if (Fetch.ekbCorpId) {
      const temp = list.filter((o) => o.corporation.id === Fetch.ekbCorpId);
      if (temp && temp.length > 0) {
        session.set('user', {
          accessToken: Fetch.accessToken,
          corpId: Fetch.ekbCorpId,
          userId: Fetch.userId,
        });
        return;
      } else {
        api.go('/selectEnterprise5');
        return;
      }
    } else {
      if (window.inGroupApp) {
        return api.go('/selectEnterprise5');
      }
      if (list.length < 1) {
        // api.go('/emptyEnterprise5')
        // 这里使用 location.replace 而不是 api.go 是因为后者会引发未知代码发送其它请求，
        // 从而导致后端返回 「缺少corpId参数」，从而导致前端代码某处报错，从而导致页面空白
        location.replace('?#/emptyEnterprise5');
        return;
      } else if (list.length === 1) {
        Fetch.ekbCorpId = data.items[0].corporation.id;
        session.set('user', {
          accessToken: Fetch.ekbCorpId,
          corpId: Fetch.accessToken,
          userId: data.items[0].userId,
        });
        if (list[0].corporation.sourceChannel === 'V1') {
          const n = new Date().getTime();
          handleRefresh(`?=${n}#/selectEnterprise5`);
        }
        if (list[0].corporation.sourceChannel === 'GROUP') {
          localStorage.setItem('wereRedirectToGroupApp', 'true');
          location.href = list[0].corporation.sourceId;
        }
        return;
      } else if (list.length > 1) {
        api.go('/selectEnterprise5');
      }
    }
  });
}

function clusterLogout() {
  const params = qs.parse(window.location.search.slice(1));
  const corpId = params?.corpId || Fetch.ekbCorpId || '';
  const existGP = corpId?.includes('~GP');
  let logoutUrl = window.PLATFORMINFO?.logoutUrl;
  logoutUrl = logoutUrl && existGP ? logoutUrl : '';
  const paramsStr = corpId ? `fromCluster=true&corpId=${corpId}` : '';
  sessionStorage.setItem('isLogOutUrl', 'true');
  location.href = `${logoutUrl}/web/app.html?${paramsStr}#/login`;
}

export default [
  {
    id: '@account5',
    reducer: () => import('./account.reducer'),
    onready() {
      handleLogin();
    },

    async refresh() {
      const { isAuthorized } = await go2AuthCheck()

      const n = new Date().getTime();
      if (Fetch.ekbCorpId) {
        if (!isAuthorized) {
          // api.go(`/auth-check`)
          handleRefresh(`?_=${n}&corpId=${Fetch.ekbCorpId}#/auth-check`);
        } else {
          handleRefresh(`?_=${n}&corpId=${Fetch.ekbCorpId}#/`);
        }
      } else {
        handleRefresh(`?_=${n}#/`);
      }
    },

    refreshToCluster() {
      const n = new Date().getTime();
      if (Fetch.ekbCorpId) {
        const params = Fetch.makeUrlParams({
          corporationId: Fetch.ekbCorpId,
          corpId: Fetch.ekbCorpId,
        });
        const path = `${window.PLATFORMINFO?.clusterURL}tenant/login?${params.slice(0)}#/`;
        handleRefresh(path);
      }
    },

    async refreshToBills() {
      if (window.IS_SMG && window.__PLANTFORM__ === 'THIRDPARTY') {
        const params = Fetch.makeUrlParams({
          ekbCorpId: Fetch.ekbCorpId,
          ccessToken: Fetch.accessToken,
        });
        setTimeout(() => {
          location.replace('?' + params.slice(0));
        }, 1000);
      } else {
        const { isAuthorized } = await go2AuthCheck()

        const n = new Date().getTime();
        if (!isAuthorized) {
          handleRefresh(`?_=${n}&corpId=${Fetch.ekbCorpId}#/auth-check`);
        }
        else {
          handleRefresh(`?_=${n}&corpId=${Fetch.ekbCorpId}#/bills`);
        }
      }
    },
    logout: function () {
      api
        .dispatch(actions.exitLogon())
        .then(() => {
          setTimeout(() => {
            const grayDomain =
              detectClientBrowser().indexOf('DingTalk') !== -1
                ? window.location.host
                : document.domain.split('.').slice(-2).join('.');
            Cookies.remove('corpId', { path: '', domain: grayDomain });
            session.clear();
            if (window.__PLANTFORM__ === 'HUAWEI') {
              location.replace(HUAWEI_LOGIN);
            } else if (window.inGroupApp) {
              // 在集团版中时，跳转至原生APP的登录页
              location.href = `${window.APP_URL}?formGroupApp=true#/login`;
            } else if (window.PLATFORMINFO?.clusterURL && window.__PLANTFORM__ !== 'MC') {
              clusterLogout();
            } else {
              api.go('/login', true);
            }
          }, 1000);
        })
        .catch(() => {
          session.clear();
          if (window.__PLANTFORM__ === 'HUAWEI') {
            location.replace(HUAWEI_LOGIN);
          } else if (window.PLATFORMINFO?.clusterURL && window.__PLANTFORM__ !== 'MC') {
            clusterLogout();
          } else {
            api.go('/login', true);
          }
        });
    },
    csxLogout: function () {
      api
        .dispatch(actions.csxSsoLogout())
        .then((data) => {
          const backurl = data?.value?.backurl;
          if (backurl) {
            setTimeout(() => {
              const grayDomain =
                detectClientBrowser().indexOf('DingTalk') !== -1
                  ? window.location.host
                  : document.domain.split('.').slice(-2).join('.');
              Cookies.remove('corpId', { path: '', domain: grayDomain });
              session.clear();
              localStorage.removeItem('isSanxiang');
              location.replace(backurl);
            }, 1000);
          } else {
            showMessage.error(data?.value?.rtnMsg || i18n.get('操作失败'));
          }
        })
        .catch(() => {
          showMessage.error(i18n.get('操作失败'));
        });
    },
    'dispatch:byCodeJoinEnterprise'(param) {
      return api.dispatch(actions.byCodeJoinEnterprise(param));
    },

    'get:country:list'() {
      return api.dispatch(actions.getCountryList());
    },

    'get:captcha'(param) {
      return api.dispatch(actions.getCaptcha(param));
    },

    'check:captcha'(param) {
      return api.dispatch(actions.checkCaptcha(param));
    },

    'account:rebind'(param) {
      return api.dispatch(actions.accountRebind(param));
    },

    'account:newbind'(param) {
      return api.dispatch(actions.accountNewbind(param));
    },

    'check:phone:email'(param) {
      return api.dispatch(actions.checkPhoneEmail(param));
    },

    'account:changeBind'(param) {
      return api.dispatch(actions.accountChangeBind(param));
    },

    clearSession() {
      api.dispatch(actions.exitLogon()).catch(() => {
        session.clear();
        api.go('/login', true);
      });
    },
    'get:read'(param) {
      return api.dispatch(actions.getRead(param));
    },
    'check:confirm'(param) {
      return api.dispatch(actions.checkConfirm(param));
    },
    'update:modify:pwd'(param) {
      return actions.modifyPassword(param)
    },
    'check:password:expire'() {
      return actions.checkPwdTimeExpire()
    },
    'password:expire:modal'(params) {
      return actions.passwordExpireModal(params)
    },
    'modify:safe:setting:account'(params) {
      return actions.modifySafeSettingAccount(params)
    },
    'auth:login:update:token'(params) {
      return AuthLoginSelectCorp2UpdateToken(params)
    }
  },
  {
    point: '@@layers',
    prefix: '@account5',
    onload: () => import('./layers'),
  },
  {
    path: '/login',
    exact: true,
    onload: () => import('./login'),
  },
  // 保留老路由，兼容老用户的收藏地址
  {
    path: '/login2',
    exact: true,
    onload: () => import('./login'),
  },
  {
    path: '/modifyPassword',
    exact: true,
    onload: () => import('./components/ModifyPassword'),
  },
  {
    path: '/register5',
    exact: true,
    onload: () => import('./register'),
  },
  {
    path: '/selectEnterprise5',
    exact: true,
    onload: () => import('./select.enterprise'),
  },
  {
    path: '/emptyEnterprise5',
    exact: true,
    onload: () => import('./empty.enterprise'),
  },
  {
    path: '/inviteEnterprise5',
    exact: true,
    onload: () => import('./invite.enterprise'),
  },
  {
    path: '/forgotPassword5',
    exact: true,
    onload: () => import('./forgot.password'),
  },
  {
    path: '/forgotPassword5/:title',
    exact: true,
    onload: () => import('./forgot.password'),
  },
  {
    path: '/modifyPassword5',
    exact: true,
    onload: () => import('./modify.password'),
  },
  {
    path: '/successfulEnterprise5',
    exact: true,
    onload: () => import('./successful.enterprise'),
  },
  {
    point: '@@layers',
    prefix: '@account5',
    onload: () => import('./layers'),
  },
  {
    path: '/createEnterpriseSuccess',
    exact: true,
    onload: () => import('./create.enterprise.success'),
  },
  {
    path: '/createEnterprise',
    exact: true,
    onload: () => import('./create.enterprise'),
  },
];
