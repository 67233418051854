import { __assign, __awaiter, __generator } from "tslib";
import { showMessage } from '@ekuaibao/show-util';
import { getMeAuthState } from '../account.action';
export function setModalTitle(actionType, type) {
    var str1 = '';
    var str2 = '';
    switch (actionType) {
        case 'rebind':
            str1 = i18n.get('更换');
            break;
        case 'newbind':
            str1 = i18n.get('绑定');
            break;
    }
    switch (type) {
        case 'cellphone':
            str2 = i18n.get('手机号');
            break;
        case 'email':
            str2 = i18n.get('邮箱');
            break;
    }
    return str1 + str2;
}
export function typeMapFormLable(type) {
    var str2 = '';
    switch (type) {
        case 'cellphone':
            str2 = i18n.get('手机号');
            break;
        case 'email':
            str2 = i18n.get('邮箱');
            break;
    }
    return str2;
}
export function stepMapPlaceholder(step, type) {
    var str1 = i18n.get('请输入您');
    var str2 = '';
    switch (step) {
        case 1:
            str1 += i18n.get('当前的');
            break;
        case 2:
            str1 += i18n.get('新的');
            break;
    }
    switch (type) {
        case 'cellphone':
            str2 = i18n.get('手机号');
            break;
        case 'email':
            str2 = i18n.get('邮箱');
            break;
    }
    return str1 + str2;
}
export function actionTypeMapNote(actionType, step, type) {
    var str1 = '';
    switch (type) {
        case 'cellphone':
            str1 = i18n.get('手机号');
            break;
        case 'email':
            str1 = i18n.get('邮箱');
            break;
    }
    switch (actionType) {
        case 'rebind':
            switch (step) {
                case 1:
                    return i18n.get("\u66F4\u6539\u524D\u9700\u6821\u9A8C\u5F53\u524D\u7ED1\u5B9A\u7684{__k0}", { __k0: str1 });
                case 2:
                    return i18n.get("\u66F4\u6539{__k0}\u540E\uFF0C\u60A8\u5C06\u4F7F\u7528\u65B0\u7684{__k0}\u8FDB\u884C\u767B\u5F55", { __k0: str1 });
            }
            break;
        case 'newbind':
            return i18n.get("\u7ED1\u5B9A{__k0}\u540E\uFF0C\u60A8\u53EF\u4F7F\u7528\u65B0\u7684{__k0}\u8FDB\u884C\u767B\u5F55", { __k0: str1 });
        default:
            return '';
    }
}
export var actionTypeMapStep = {
    rebind: 1,
    newbind: 2
};
export var captchaWayMapNumber = {
    email: 1,
    cellphone: 2,
    voice: 3
};
export function mapAccount(data, type) {
    var str1 = '';
    switch (type) {
        case 'cellphone':
            str1 = data['areaCode'] + '-' + data['cellphone'];
            break;
        case 'email':
            str1 = data['email'];
            break;
        case 'voice':
            str1 = data['areaCode'] + '-' + data['cellphone'];
            break;
    }
    return str1;
}
export function mapFieldsName(type) {
    var arr = [];
    switch (type) {
        case 'cellphone':
            arr = ['areaCode', 'cellphone', 'captchaCode'];
            break;
        case 'email':
            arr = ['email', 'captchaCode'];
            break;
    }
    return arr;
}
export function formateCheckCaptchaParams(values, type, actionType, captchaId) {
    var data = __assign({}, values);
    var account = mapAccount(data, type);
    var params = {
        bizType: actionType,
        account: account,
        token: captchaId,
        captcha: data.captchaCode
    };
    return params;
}
export function formateParams(values, type, actionType, captchaId, secureToken) {
    var data = __assign({}, values);
    var account = mapAccount(data, type);
    var params = {
        account: account,
        way: captchaWayMapNumber[type],
        token: captchaId,
        captcha: data.captchaCode,
        secureToken: secureToken
    };
    return params;
}
export function wayMapMessage(way) {
    switch (way) {
        case 1:
        case 2:
        case '1':
        case '2':
            showMessage.success(i18n.get('验证码已发送'));
            break;
        case 3:
        case '3':
            showMessage.success(i18n.get('语音验证码已发送，请注意接听'));
            break;
    }
}
export function go2AuthCheck() {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var meInfo, isAuthorized, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getMeAuthState()];
                case 1:
                    meInfo = _b.sent();
                    isAuthorized = (_a = meInfo === null || meInfo === void 0 ? void 0 : meInfo.value) === null || _a === void 0 ? void 0 : _a.isAuthorized;
                    console.log('初始化auth===', isAuthorized);
                    return [2 /*return*/, { isAuthorized: isAuthorized }];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, Promise.resolve({ isAuthorized: true })];
                case 3: return [2 /*return*/];
            }
        });
    });
}
